<template>
    <b-container>
        <b-table striped hover :fields="fields" :items="editais">
            <template #cell(id)="data">
                <b-button :to="`/pages/edital-verticalizado/edital/${data.item.id}`" variant="primary">Ver</b-button>
            </template>
        </b-table>
    </b-container>
</template>

<script>
import {
	BRow,
	BCol,
    BTable,
    BContainer,
    BButton
} from "bootstrap-vue";
export default {
	components: {
		BRow,
		BCol,
        BTable,
        BContainer,
        BButton
	},
    data: () => ({
        fields: ['id','nome'],
        editais: []
    }),
    methods:{
        getEdital(){
            this.$http.get('editais').then( response => {
                this.editais = response.data
            })
        }
    },
    created(){
        this.getEdital()
    }
};
</script>

<style>
</style>